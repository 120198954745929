
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter,RouteParams } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      newPassword: Yup.string().min(4).required().label("Password"),
      confirmNewPassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .label("Password Confirmation"),
      
    });

    const onSubmitNewPassword = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");


        values.token = router.currentRoute.value.query.token
        values.guid = router.currentRoute.value.query.guid
         console.log("reset values:")
        console.log(values)
        // Send register request
        store
          .dispatch(Actions.NEW_PASSWORD, values)
          .then(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
             router.push({ name: "emailverify" });
             
          })
          .then(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: "Your password has been changed",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        
      
    };

    return {
      registration,
      onSubmitNewPassword,
      submitButton,
    };
  },
});
